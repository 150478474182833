/* Stars.css */

html {
    overflow-x: hidden;
    overflow-y: hidden;
}

.star-large {
    position: absolute;
    width: 265px;
    height: 265px;
    animation-name: moveStarLarge;
    animation-duration: 15s, 20s, 10s;
    animation-iteration-count: infinite;
  }
  
.star-medium {
    position: absolute;
    width: 210px;
    height: 210px;
    animation-name: moveStarMedium;
    animation-duration: 15s, 20s, 10s;
    animation-iteration-count: infinite;
}

.star-small {
    position: absolute;
    width: 160px;
    height: 160px;
    animation-name: moveStarSmall;
    animation-duration: 15s, 20s, 10s;
    animation-iteration-count: infinite;
}

.star-large-2 {
    position: absolute;
    width: 265px;
    height: 265px;
    margin-top: 200px;
    margin-left: 1000px;
    animation-name: moveStarBounce, moveStarRotate;
    animation-duration: 15s, 20s, 10s;
    animation-iteration-count: infinite;
}

.star-medium-2 {
    position: absolute;
    width: 210px;
    height: 210px;
    margin-top: 600px;
    margin-left: 40px;
    animation-name: moveStarBounce, moveStarRotate;
    animation-duration: 15s, 20s, 10s;
    animation-iteration-count: infinite;
}

.star-small-2 {
    position: absolute;
    width: 160px;
    height: 160px;
    margin-top: 10px;
    margin-left: 400px;
    animation-name: moveStarBounce, moveStarRotate;
    animation-duration: 15s, 20s, 10s;
    animation-iteration-count: infinite;
}

@media (max-width: 390px) {

  .star-large {
    display: none;
  }
  
  .star-medium {
    display: none;
  }

  .star-small {
    position: absolute;
    width: 160px;
    height: 160px;
    margin-top: 500px;
    margin-left: 160px;
    animation-name: moveStarBounce, moveStarRotate;
    animation-duration: 15s, 20s, 10s;
    animation-iteration-count: infinite;
  }

  .star-large-2 {
    display: none;
  }

  .star-medium-2 {
    display: none;
  }

  .star-small-2 {
    position: absolute;
    width: 160px;
    height: 160px;
    margin-top: 0px;
    margin-left: 10px;
    animation-name: moveStarBounce, moveStarRotate;
    animation-duration: 15s, 20s, 10s;
    animation-iteration-count: infinite;
  }
}
  
@keyframes moveStarLarge {
  from {
    left: 0;
    top: 0;
    transform: rotate(0deg);
  }
to {
      top: calc(100% - 265px);
      left: calc(100% - 265px);
      transform: rotate(45deg);
  }
}
  
@keyframes moveStarMedium {
  from {
    left: 0;
    bottom: 0;
    transform: rotate(0deg);
  }
to {
      bottom: calc(100% - 265px);
      left: calc(100% - 265px);
      transform: rotate(45deg);
  }
}

@keyframes moveStarSmall {
  from {
    right: 0;
    bottom: 0;
    transform: rotate(0deg);
  }
to {
      bottom: calc(100% - 265px);
      right: calc(100% - 265px);
      transform: rotate(45deg);
  }
}

@keyframes moveStarRotate {
  from {
    transform: rotate(0deg);
  }
to {
      transform: rotate(360deg);
  }
}
  
@keyframes moveStarBounce {
  0%, 100% {
    transform: translateY(0);
  }
50% {
      transform: translateY(-100px);
  }
}
