/* Header.css */

.header {
    color: black;
    text-decoration: none;
}

.marquee {
    animation: marquee 10s infinite linear;
    font-size: 80px;
    margin-top: 35px;
    margin-bottom: 35px;
    font-family: 'PressStart2P-Regular';
    color: black;
    text-decoration: none;
    text-transform: capitalize;
    white-space: nowrap;
}

@media (max-width: 390px) {

    .marquee {
        column-width: 390px;
        margin-top: 20px;
        margin-bottom: 20px;
        animation: marquee 5s infinite linear;
        font-size: 60px;
        font-family: 'PressStart2P-Regular';
        color: black;
        text-decoration: none;
        text-transform: capitalize;
    }
}

@keyframes marquee {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}
