/* Input.css */

.button-shopping-bag {
    position: absolute;
    top: 20%;
    left: 95%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 80px;
    padding-top: 20px;
    background-color: transparent;
    background-image: url("images/shopping-bag.svg");
    cursor: pointer;
    border: none;
    font-size: 48px;
    font-family: 'VT323-Regular';
}

.enter-dream {
    position: absolute;
    top: 30%;
    left: 20%;
    transform: translate(-50%, -50%);
    margin-top: 50px;
    margin-left: 40px;
    font-size: 32px;
}

.main-input {
    outline: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    height: 80px;
    border: 7px solid black;
    font-size: 32px;
    font-family: 'VT323-Regular';
    text-transform: lowercase;
}

.error-input {
    outline: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    height: 80px;
    border: 7px solid #FF9900;
    font-size: 32px;
    font-family: 'VT323-Regular';
    text-transform: lowercase;
}

.error-message {
    position: absolute;
    top: 60%;
    left: 30%;
    transform: translate(-50%, -50%);
    width: 400px;
    font-size: 20px;
    color: #FF9900;
}

@media (max-width: 390px) {

    .button-shopping-bag {
            top: 25%;
            left: 85%;
            transform: translate(-50%, -50%);
            padding: 25px 10px;
            background-color: transparent;
            background-image: url("images/shopping-bag.svg");
            cursor: pointer;
            border: none;
            font-size: 48px;
            font-family: 'VT323-Regular';
            color: black;
        }
        
    .enter-dream {
            top: 33%;
            left: 45%;
            transform: translate(-50%, -50%);
            width: 300px;
            margin-top: 50px;
            margin-left: 40px;
            font-size: 24px;
        }
        
    .main-input {
            outline: none;
            top: 55%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 220px;
            height: 50px;
            border: 7px solid black;
            font-size: 24px;
            font-family: 'VT323-Regular';
            text-transform: lowercase;
        }
        
    .error-input {
            outline: none;
            top: 55%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 220px;
            height: 50px;
            border: 7px solid #FF9900;
            font-size: 24px;
            font-family: 'VT323-Regular';
            text-transform: lowercase;
        }
        
    .error-message {
            top: 65%;
            left: 45%;
            transform: translate(-50%, -50%);
            width: 300px;
            margin-left: 40px;
            font-size: 18px;
            color: #FF9900;
        }
}