/* App.css */

@font-face {
    src: url('./fonts/PressStart2P-Regular.ttf') format('truetype');
    font-family: 'PressStart2P-Regular';
  }

@font-face {
    src: url('./fonts/VT323-Regular.ttf') format('truetype');
    font-family: 'VT323-Regular';
}

body {
    background-image: url("images/background.png");
    background-size: cover;
    font-family: 'VT323-Regular';
    text-transform: lowercase;
}

.main-message {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 1000px;
    height: 600px;
    margin: auto;
    background-color: #FFF3FC;
    border: 7px solid black;
}

.main-message-title {
    position: absolute;
    z-index: 1;
    left: 15%;
    transform: translate(-50%, -50%);
    color: white;
}

.green-square {
    width: 50px;
    height: 50px;
    background-color: #E0FF89;
    border-bottom: 7px solid black;
}

.button-back {
    width: 50px;
    height: 57px;
    background-image: url("images/button-back.svg");
    cursor: pointer;
    border: none;
    border-bottom: 7px solid black;
}

.button-back:hover {
    background-image: url("images/button-back-hover.svg");
}

.pink-title {
    position: relative;
    width: 950px;
    height: 50px;
    background-color: #FF89E5;
    border-bottom: 7px solid black;
    border-left: 7px solid black;
}

.welcome-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.greeting-one {
    font-size: 48px;
    text-align: center;
}

.greeting-two {
    font-size: 36px;
    text-align: center;
}
    
.button-primary {
    display: block;
    position: absolute;
    top: 85%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 260px;
    height: 60px;
    background-color: #CB89FF;
    cursor: pointer;
    border: 7px solid black;
    font-size: 14px;
    font-family: 'PressStart2P-Regular';
    color: #fff;
    text-transform: lowercase;
}
  
.button-primary:hover {
    background-color: #8F00FF;
}

.footer {
    position: fixed;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 30px;
    color: black;
    text-decoration: none;
}

@media (max-width: 390px) {

    .main-message {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            position: relative;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 300px;
            height: 500px;
            margin: auto;
            background-color: #FFF3FC;
            border: 7px solid black;
        }
        
    .main-message-title {
            z-index: 1;
            left: 55%;
            transform: translate(-50%, -50%);
            width: 200px;
            color: white;
        }
        
    .green-square {
            width: 50px;
            height: 50px;
            background-color: #E0FF89;
            border-bottom: 7px solid black;
        }
        
    .button-back {
            width: 50px;
            height: 57px;
            background-image: url("images/button-back.svg");
            cursor: pointer;
            border: none;
            border-bottom: 7px solid black;
        }
        
    .button-back:hover {
            background-image: url("images/button-back-hover.svg");
        }
        
    .pink-title {
            width: 250px;
            height: 50px;
            background-color: #FF89E5;
            border-bottom: 7px solid black;
            border-left: 7px solid black;
        }
        
    .welcome-message {
            top: 45%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 250px;
        }
        
    .greeting-one {
            font-size: 48px;
            text-align: center;
        }
        
    .greeting-two {
            font-size: 32px;
            text-align: center;
        }
            
    .button-primary {
            display: block;
            top: 85%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 260px;
            height: 60px;
            background-color: #CB89FF;
            cursor: pointer;
            border: 7px solid black;
            font-size: 14px;
            font-family: 'PressStart2P-Regular';
            color: #fff;
            text-transform: lowercase;
        }
        
    .button-primary:hover {
            background-color: #8F00FF;
        }
        
    .footer {
            left: 50%;
            transform: translate(-50%, -50%);
            margin-top: 30px;
            color: black;
            text-decoration: none;
        }
  }
